import React from "react";
import './wo.css'
export function WeeklyOverview  () {

  return ( <>
      <div className="wo1">
        <h1> Weekly Overview</h1>
        <h2> date</h2>
        <ol>
        <ls>fist</ls>
        <li>cat</li>
        </ol>
      </div>
    </>
    );
};
export default WeeklyOverview;