import React from "react";
import TileList from "../tileList/TileList";

export const ContactPicker = () => {
  return (
  
     <div>
      <TileList />
      {/* <section>
        <h2>Sean Combs</h2> 
      </section>
      <hr />
      <section>
        <h2>Andrew Rizzo</h2>
      </section> */}
    </div>

  );
};
export default ContactPicker ;